import React, { useState } from 'react';
import { t } from '@lingui/macro';

import { Button, Paragraph, Text } from '@rover/kibble/core';
import Row from '@rover/kibble/patterns/Row';
import { DSTokenMap } from '@rover/kibble/styles';
import OnOffSwitch from '@rover/react-lib/src/components/buttons/OnOffSwitch';
import BottomInfoModal from '@rover/react-lib/src/components/modals/BottomInfoModal';
import { RECURRING_SWITCH_TEXT } from '@rover/react-lib/src/pages/contact-sitter/ContactSitterPage/components/ContactSitterForm/components/ServiceScheduler/constants';
import {
  StyledInfoIcon,
  ToggleProps,
} from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Dates/SimplifiedMobileOwnerSearchFormScheduler';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

const OneTimeRepeatSwitch = ({ on, onChange }: ToggleProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useI18n();

  return (
    <>
      <Row
        helperItem={
          <Button
            variant="flat"
            aria-label={i18n._(t`Learn more about recurring services`)}
            onClick={() => setIsOpen(true)}
            backgroundColor={DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString()}
            padding="0x"
          >
            <StyledInfoIcon />
          </Button>
        }
        label={
          <Text fontWeight="semibold" size="100">
            {i18n._(RECURRING_SWITCH_TEXT)}
          </Text>
        }
        rightItem={
          <OnOffSwitch
            disabled={false}
            label={i18n._(RECURRING_SWITCH_TEXT)}
            on={on}
            onToggle={(value): void => onChange(value)}
            hideLabel
          />
        }
      />
      <BottomInfoModal
        heading={i18n._(t`Weekly bookings`)}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        text={
          <>
            <Paragraph size="200" mb="6x">
              {i18n._(
                t`Weekly bookings allow you to choose whether you want a booking to repeat automatically every week.`
              )}
            </Paragraph>
            <Paragraph size="200" mb="6x">
              {i18n._(
                t`With this feature, bookings take place on the same day of the week and they'll repeat on an ongoing basis. You can discontinue recurring bookings at any time.`
              )}
            </Paragraph>
          </>
        }
        buttonText={i18n._(t`Got it`)}
      />
    </>
  );
};

export default OneTimeRepeatSwitch;
