import React, { useCallback, useId, useState } from 'react';
import { t } from '@lingui/macro';

import { Box, Button, Flex, Heading, Text } from '@rover/kibble/core';
import BottomSheet from '@rover/kibble/official-patterns/BottomSheet';
import ClickableInput from '@rover/kibble/patterns/ClickableInput';
import FormBasicValidationError from '@rover/react-lib/src/components/formFields/FormValidationError/FormBasicValidationError';
import RowList from '@rover/react-lib/src/components/RowList';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { Pet, SearchFilters } from '@rover/types';
import { HomepageSearchFilters } from '@rover/types/src/HomepageSearchFilters';

import PetSelectorRow, { SelectedPetProps } from './PetSelectorRow';

export type Props = {
  onChange: (searchFilters: Partial<SearchFilters>) => void;
  petOptions: Pet[];
  searchFilters: Pick<HomepageSearchFilters, 'pet' | 'petType'>;
  hideHelperText?: boolean;
  showErrorMessage?: boolean;
};

const SimplifiedMobileOwnerSearchPetSelector = ({
  onChange,
  petOptions,
  searchFilters,
  hideHelperText = false,
  showErrorMessage = false,
}: Props): JSX.Element => {
  const { i18n } = useI18n();
  const [petsOpen, setPetsOpen] = useState(false);
  const staticId = useId();

  const getPetNamesString = (pets: string[]): string => {
    if (pets.length === 0) {
      return i18n._(t`No pets selected`);
    }
    const selectedPets = petOptions.filter((pet) => pets.includes(pet.pk.toString()));
    return selectedPets.map((pet) => pet.name).join(', ');
  };
  const handleChange = ({ pet, petType }: SelectedPetProps): void => {
    if (!pet) return;

    const selectedPets = searchFilters.pet || [];

    if (selectedPets.includes(pet)) {
      const newPets = selectedPets.filter((p) => p !== pet);
      const newPetOptions = petOptions.filter((p) => newPets.includes(p.pk.toString()));
      const newPetTypes = [...new Set(newPetOptions.map((p) => p.petType))];
      onChange({ pet: newPets, petType: newPetTypes });
    } else {
      onChange({
        pet: [...selectedPets, pet],
        petType: [...new Set([...(searchFilters.petType || []), petType])],
      });
    }
  };

  const closeSheet = useCallback(() => setPetsOpen(false), []);

  return (
    <>
      <ClickableInput
        data-testid="pet-selector-input"
        {...(!hideHelperText && {
          helperText: i18n._(t`For a more accurate search, select at least 1 pet`),
        })}
        inputLabel={i18n._(t`My pets`)}
        onClick={() => setPetsOpen(!petsOpen)}
      >
        <Text size="200" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', textWrap: 'nowrap' }}>
          {getPetNamesString(searchFilters.pet || [])}
        </Text>
      </ClickableInput>
      <BottomSheet
        onRequestClose={closeSheet}
        isFullHeight={petOptions.length > 4}
        isOpen={petsOpen}
      >
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height={petOptions.length > 4 ? '100%' : 'auto'}
        >
          <Flex paddingBottom="0x" flexDirection="column" width="100%">
            <Heading size="200" textAlign="center">
              {i18n._(t`My pets`)}
            </Heading>
            <Box paddingY="6x" paddingX="0x">
              <RowList>
                {petOptions.map(({ name, pk, petType }) => (
                  <PetSelectorRow
                    key={name}
                    name={name}
                    value={pk}
                    petType={petType}
                    staticId={staticId}
                    handleChange={handleChange}
                    searchFilters={searchFilters}
                  />
                ))}
              </RowList>
              {!searchFilters.pet?.length && showErrorMessage && (
                <FormBasicValidationError errorMessage={i18n._('Add at least one pet')} />
              )}
            </Box>
          </Flex>
          <Button variant="primary" size="small" fullWidth onClick={() => setPetsOpen(false)}>
            {i18n._(t`Save pets`)}
          </Button>
        </Flex>
      </BottomSheet>
    </>
  );
};

export default SimplifiedMobileOwnerSearchPetSelector;
