import React, { FC, useId, useRef } from 'react';

import { Text } from '@rover/kibble/core';
import Row from '@rover/kibble/patterns/Row';
import FancyCheckbox from '@rover/react-lib/src/components/formFields/FancyCheckbox/FancyCheckbox';
import { HomepageSearchFilters } from '@rover/types/src/HomepageSearchFilters';
import { PetType } from '@rover/types/src/Pet';

export type SelectedPetProps = {
  pet: string;
  petType: PetType;
};

export type PetSelectorRowProps = {
  name: string;
  value: number | '';
  petType: PetType;
  staticId: string;
  handleChange: (selectedPet: SelectedPetProps) => void;
  searchFilters: Pick<HomepageSearchFilters, 'pet' | 'petType'>;
};

const PetSelectorRow: FC<PetSelectorRowProps> = ({
  name,
  value,
  petType,
  staticId,
  handleChange,
  searchFilters,
}) => {
  const petSelectorRef = useRef<HTMLDivElement>(null);
  const inputId = useId();

  return (
    <Row
      ref={petSelectorRef}
      onClick={() => {
        handleChange({ pet: value.toString(), petType });
      }}
      width="100%"
      label={
        <Text
          sx={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {name}
        </Text>
      }
      labelHtmlFor={inputId}
      key={name}
      rightItem={
        <FancyCheckbox
          labelIsProvidedViaHtmlFor
          actualId={inputId}
          checked={searchFilters.pet?.includes(value.toString())}
          id={staticId}
          label={name}
          hideLabel
        />
      }
    />
  );
};

export default PetSelectorRow;
