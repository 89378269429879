import React, { useCallback, useContext, useMemo } from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { Box, Text } from '@rover/kibble/core';
import { SpacingSystemType } from '@rover/kibble/core/styledSystemPropTypes';
import { DSTokenMap } from '@rover/kibble/styles';
import { SPACE_2X, SPACE_6X, SPACE_12X } from '@rover/kibble/tokens/build/es6/DSToken';
import GenericCalendar, {
  CalendarType,
} from '@rover/react-lib/src/components/datetime/GenericCalendar';
import { getInitialDate } from '@rover/react-lib/src/pages/contact-sitter/ContactSitterPage/components/ContactSitterForm/components/ServiceScheduler/utils';
import { SearchPageContext } from '@rover/react-lib/src/pages/search/SearchPage/utilities/searchPageContext';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { Day } from '@rover/types';

export type Props = {
  label: string;
  language: string;
  maxDate?: Date;
  onChange: (arg0: { startDate?: Date | null; endDate?: Date | null; fireSearch: boolean }) => void;
  selectedDays: Day[];
  serviceName: string;
  startPlaceholder: string;
  endPlaceholder: string;
  shouldShowSublabel: boolean;
  isMobileBrowser?: boolean;
  onClickFromSearch?: () => void;
  disableValidation?: boolean;
  bottomPadding?: SpacingSystemType;
};

const StyledLabel = styled.label`
  display: block;
  width: 100%;
  margin-bottom: ${DSTokenMap.SPACE_1X.toString()};
  font-weight: 700;
  color: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
`;

const OneTimeServiceScheduler = ({
  endPlaceholder,
  label,
  language,
  maxDate,
  onChange,
  serviceName,
  startPlaceholder,
  selectedDays,
  shouldShowSublabel = true,
  isMobileBrowser = false,
  onClickFromSearch,
  disableValidation,
  bottomPadding,
}: Props): JSX.Element => {
  const { i18n } = useI18n();
  const {
    isRollout1SimplifiedMobileOwnerSearchFormExperiment,
    inVariantSimplifiedMobileOwnerSearchFormExperiment,
  } = useContext(SearchPageContext);

  const handleSelectedDaysChange = useCallback((days: Day[]): void => {
    const startDate = days[0]?.date || null;
    const endDate = days.length > 1 ? days.slice(-1)[0]?.date : null;
    onChange({ startDate, endDate, fireSearch: !!(startDate && endDate) });
  }, []);

  const minDate = useMemo(() => new Date(), []);
  return (
    <>
      <Box paddingBottom={bottomPadding || '2x'}>
        <StyledLabel>{label}</StyledLabel>
        {shouldShowSublabel && (
          <Text size="100" textColor="success" mb="2x">
            {i18n._(t`Add dates to see available sitters`)}
          </Text>
        )}
      </Box>
      <GenericCalendar
        calendarType={CalendarType.Range}
        language={language}
        onChange={handleSelectedDaysChange}
        selectedDays={selectedDays || []}
        isMobileBrowser={isMobileBrowser}
        serviceName={serviceName}
        maxDate={maxDate}
        disableBeforeDateTime={minDate}
        disableAfterDateTime={maxDate}
        newCalendarInteraction
        validationType="inline"
        showTwoInputs
        startDatePlaceholder={startPlaceholder || i18n._(t`Start date`)}
        endDatePlaceholder={endPlaceholder || i18n._(t`End date`)}
        disableValidation={disableValidation}
        {...(isRollout1SimplifiedMobileOwnerSearchFormExperiment &&
          inVariantSimplifiedMobileOwnerSearchFormExperiment && {
            inputStyle: { minHeight: SPACE_12X },
            iconStyle: {
              height: SPACE_6X,
              width: SPACE_6X,
              right: SPACE_2X,
            },
          })}
        onClickFromSearch={onClickFromSearch}
        initialMonth={getInitialDate(selectedDays || [])}
        isDismissible={false}
      />
    </>
  );
};

export default OneTimeServiceScheduler;
