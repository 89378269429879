import React, { useCallback, useMemo } from 'react';
import { DateUtils } from 'react-day-picker';
import { t } from '@lingui/macro';
import moment from 'moment';
import styled from 'styled-components';

import { AlertInfo } from '@rover/icons';
import { Box } from '@rover/kibble/core';
import { SpacingSystemType } from '@rover/kibble/core/styledSystemPropTypes';
import { getMiddleDays } from '@rover/react-lib/src/components/datetime/MultiDateAutoFillPicker/MultiDateAutoFillPickerGeneric';
import dayFactory from '@rover/react-lib/src/factories/dayFactory';
import {
  getBooleanFromFrequency,
  getFrequencyFromBoolean,
} from '@rover/react-lib/src/pages/contact-sitter/ContactSitterPage/components/ContactSitterForm/components/ServiceScheduler/utils';
import getMaxRequestDate from '@rover/react-lib/src/utils/getMaxRequestDate';
import { DateTimeFormatType, useI18n } from '@rover/rsdk/src/modules/I18n';
import OneTimeRepeatSwitch from '@rover/shared/js/search/components/OneTimeRepeatSwitch';
import OneTimeServiceScheduler from '@rover/shared/js/search/components/OneTimeServiceScheduler';
import RepeatServiceScheduler from '@rover/shared/js/search/components/RepeatServiceScheduler';
import { Day, SearchFilters, ServiceType } from '@rover/types';
import { HomepageSearchFilters } from '@rover/types/src/HomepageSearchFilters';
import { ServiceFrequencyEnum } from '@rover/types/src/ServiceFrequency';

export const StyledInfoIcon = styled(AlertInfo)`
  width: 16px;
  height: 16px;
  margin-top: 6px;
`;

export type Props = {
  language: string;
  onChange: (arg0?: ServiceFrequencyEnum) => void;
  onDateRangeChange: (arg0: {
    startDate?: Date | null;
    endDate?: Date | null;
    fireSearch: boolean;
  }) => void;
  onSelectedDaysChange: (arg0: Day[]) => void;
  onStartDateChange: (arg0: {
    startDate?: Date | null;
    endDate?: Date | null;
    fireSearch: boolean;
  }) => void;
  filters: SearchFilters | HomepageSearchFilters;
  serviceType?: ServiceType | null;
  showDateRange: boolean;
  emitEvent?: () => void;
  showSubTitle: boolean;
  bottomPadding?: SpacingSystemType;
};

export type ToggleProps = {
  on: boolean;
  onChange: (arg0: boolean) => void;
};

const makeDayFromDate = (date: Date, DF: DateTimeFormatType): Day => {
  return dayFactory({
    name: moment(date).format(DF.MONTH_DAY_MED),
    date,
  });
};

const SimplifiedMobileOwnerSearchFormScheduler = ({
  language,
  onChange,
  onDateRangeChange,
  onSelectedDaysChange,
  onStartDateChange,
  filters,
  serviceType,
  showDateRange,
  emitEvent,
  showSubTitle,
  bottomPadding,
}: Props): JSX.Element => {
  const { i18n, DF } = useI18n();
  useMemo(() => moment.locale(language), [language]);
  const { selectedDays, startDate, endDate, frequency } = filters;
  const maxDate = useMemo(() => getMaxRequestDate(), []);

  const getSelectedDays = useCallback((): [] | Day[] => {
    if (!startDate && !endDate) return [];

    if (startDate && endDate) {
      const startDay = makeDayFromDate(startDate, DF);
      const endDay = makeDayFromDate(endDate, DF);
      return getMiddleDays(startDay, endDay, DF);
    }

    if (startDate) {
      return [makeDayFromDate(startDate, DF)];
    }

    if (endDate) {
      return [makeDayFromDate(endDate, DF)];
    }

    return [];
  }, [startDate, endDate, DF]);

  const handleRepeatServiceSchedulerStartDateChange = useCallback((selectedStartDate): void => {
    if (endDate) {
      const updatedEndDate = DateUtils.isDayAfter(selectedStartDate, endDate) ? undefined : endDate;
      return onStartDateChange({
        startDate: selectedStartDate,
        endDate: updatedEndDate,
        fireSearch: true,
      });
    }

    return onStartDateChange({
      startDate: selectedStartDate,
      endDate: undefined,
      fireSearch: true,
    });
  }, []);

  const showRepeatToggle = serviceType && serviceType.name && !showDateRange;
  const showSublabel = (!startDate || !endDate) && showSubTitle;

  const paddingStyle = showRepeatToggle ? '3x' : '4x';
  const repeatToggleStyle = bottomPadding || paddingStyle;

  return (
    <>
      {showRepeatToggle && (
        <OneTimeRepeatSwitch
          onChange={(value): void => {
            onChange(getFrequencyFromBoolean(value));
          }}
          on={frequency ? getBooleanFromFrequency(frequency) : false}
        />
      )}
      <Box pt={repeatToggleStyle}>
        {frequency === ServiceFrequencyEnum.ONE_TIME || showDateRange ? (
          <OneTimeServiceScheduler
            label={i18n._(t`Which dates do you need?`)}
            language={language}
            maxDate={maxDate}
            onChange={onDateRangeChange}
            serviceName={serviceType ? serviceType.displayName : ''}
            startPlaceholder={i18n._(t`Start`)}
            endPlaceholder={i18n._(t`End`)}
            selectedDays={getSelectedDays()}
            shouldShowSublabel={showSublabel}
            isMobileBrowser
            onClickFromSearch={emitEvent}
            disableValidation
            bottomPadding={bottomPadding}
          />
        ) : (
          <RepeatServiceScheduler
            dayOfWeekLabel={i18n._(t`What weekdays?`)}
            language={language}
            maxDate={maxDate}
            onSelectedDaysChange={onSelectedDaysChange}
            onStartDateChange={handleRepeatServiceSchedulerStartDateChange}
            repeatStartDateLabel={i18n._(t`Starting on`)}
            selectedDays={selectedDays || []}
            serviceTypeSlug={serviceType && serviceType.slug}
            showLabel
            startDate={startDate}
            newCalendarInteraction
            serviceName={serviceType ? i18n._(t`Weekly ${serviceType.displayName}`) : ''}
            serviceSubtitle={i18n._(t`Select start date`)}
            startPlaceholder={i18n._(t`Start date`)}
            disableValidation
            isMobileBrowser
            onClickFromSearch={emitEvent}
          />
        )}
      </Box>
    </>
  );
};

export default SimplifiedMobileOwnerSearchFormScheduler;
