// https://media.giphy.com/media/U5u6yRVxJRhZK/giphy.gif
import React, { FC, useId } from 'react';
import styled, { css } from 'styled-components';

import { Box, Text } from '@rover/kibble/core';
import { A11yHidden, DSTokenMap, MQ, Spacing } from '@rover/kibble/styles';
import { Props as InputProps } from '@rover/react-lib/src/components/formFields/Input/Input';

import { StyledFancyCheckboxLabel } from '../FancyCheckbox';
import checkIcon from '../FancyCheckbox/check.svg?url';

import { SizeType } from './FancyRadio.types';

const RADIO_SIZE = '24px';

export type Props = Omit<InputProps, 'style' | 'onClick'> & {
  disabled?: boolean;
  name: string;
  value: string;
  checked: boolean;
  labelIsProvidedViaHtmlFor?: boolean;
  label?: React.ReactNode;
  hideLabel?: boolean;
  optionWidth?: string;
  styleAsRows?: boolean;
  className?: string;
  actualId?: string;
  id?: string;
  size?: SizeType;
  style?: React.CSSProperties;
  onChange: (value: string) => void;
};

type StyledFancyRadioFigureProps = {
  checked: boolean;
  size?: SizeType;
};

// eslint-disable-next-line rover/prefer-kibble-components
export const StyledFancyRadioFigure = styled.div<StyledFancyRadioFigureProps>`
  position: relative;
  cursor: pointer;
  user-select: none;
  width: ${(props) => props.size || RADIO_SIZE};
  height: ${(props) => props.size || RADIO_SIZE};
  border: 2px solid transparent;
  background-color: ${DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString()};
  border-radius: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
`;

type StyledHiddenRadioProps = {
  disabled: boolean;
  size?: SizeType;
};

export const StyledHiddenRadio = styled.input<StyledHiddenRadioProps>`
  ${A11yHidden};
  margin: 0;
  width: ${(props) => props.size || RADIO_SIZE};
  height: ${(props) => props.size || RADIO_SIZE};

  /* States: */
  &:not([disabled]) {
    /* State: Default */
    & ~ ${StyledFancyRadioFigure} {
      border-color: ${DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY.toString()};
    }

    /* State: Default--hover */
    &:hover,
    &:focus {
      & + ${StyledFancyRadioFigure} {
        border-color: ${DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_PRESSED.toString()};
      }
    }

    &:focus {
      & + ${StyledFancyRadioFigure} {
        /*
            Simulates default browser focus outline
            taken from /src/frontend/app/css/vendor/bootstrap/scss/mixins/_tab-focus.scss
          */
        /* Default */
        outline-width: 2px;
        outline-style: solid;
        outline-color: Highlight;
        /* Webkit */
        outline: thin dotted;
        outline: 5px auto -webkit-focus-ring-color;
      }
    }

    &:checked {
      & + ${StyledFancyRadioFigure} {
        /* State: Active */
        border-color: ${DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE.toString()};
        background-image: url(${checkIcon});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        background-color: ${DSTokenMap.INTERACTIVE_BACKGROUND_COLOR_PRIMARY_ACTIVE.toString()};

        /* State: Active--hover */
        &:hover,
        &:focus {
          border-color: ${DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE_PRESSED.toString()};
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100%;
          background-color: ${DSTokenMap.INTERACTIVE_BACKGROUND_COLOR_PRIMARY_ACTIVE_PRESSED.toString()};
        }
      }
    }
  }

  /* State: Conflict */
  &[disabled] {
    & + ${StyledFancyRadioFigure} {
      border-color: ${DSTokenMap.INTERACTIVE_BORDER_COLOR_DISABLED.toString()};
      cursor: not-allowed;
    }
  }
`;

type StyledFancyRadioLabelProps = {
  optionWidth: string;
  styleAsRows: boolean;
  disabled: boolean;
  size?: SizeType;
};

const rowsStyles = ({ optionWidth }: StyledFancyRadioLabelProps) => css`
  align-items: center;
  width: ${optionWidth};
  flex-direction: row;
  padding: 15px;

  /* Styling for the 1st row */
  border: 1px solid ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()};
  border-bottom: 0;
  border-radius: ${DSTokenMap.BORDER_RADIUS_SECONDARY} ${DSTokenMap.BORDER_RADIUS_SECONDARY} 0 0;

  /* Styling for subsequent rows */
  & ~ & {
    border-top: 1px solid ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()};
    border-radius: 0;
  }

  /* Styling for the final row */
  &:last-of-type {
    border-bottom: 1px solid ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()};
    border-radius: 0 0 ${DSTokenMap.BORDER_RADIUS_SECONDARY} ${DSTokenMap.BORDER_RADIUS_SECONDARY};
  }

  ${MQ.XS.toString()} {
    align-items: flex-start;
  }
`;

const nonRowsStyles = ({ optionWidth, size = RADIO_SIZE }: StyledFancyRadioLabelProps) => css`
  flex: 1 1 ${optionWidth};
  min-height: ${size};
  padding-right: ${Spacing.L.toString()};
`;

export const StyledFancyRadioLabel = styled(StyledFancyCheckboxLabel)<StyledFancyRadioLabelProps>`
  display: flex;
  margin-bottom: ${({ styleAsRows }) => (styleAsRows ? '0' : DSTokenMap.SPACE_3X)};
  ${(props) => (props.styleAsRows ? rowsStyles(props) : nonRowsStyles(props))};
`;

const FancyRadio: FC<Props> = ({
  className = '',
  disabled = false,
  labelIsProvidedViaHtmlFor = false,
  label = '',
  hideLabel = false,
  actualId = undefined,
  id = undefined,
  optionWidth = '100%',
  styleAsRows = false,
  value,
  checked,
  onChange,
  name,
  style,
  size = RADIO_SIZE,
  ...other
}) => {
  const defaultId = useId();

  // Append the autogenerated ID to the passed-in ID in case the same component is included twice
  // (e.g., once for wide and once for narrow screens).
  const prefix = id || '';
  const elementId = actualId || `${prefix}-${defaultId}`;

  const radioInput = (
    <>
      <StyledHiddenRadio
        data-testid={`${id}-radio`}
        tabIndex={0}
        type="radio"
        disabled={disabled}
        id={elementId}
        name={name}
        value={value}
        checked={checked}
        aria-checked={checked}
        size={size}
        onChange={(event) => {
          event.stopPropagation();
          onChange(value);
        }}
        onClick={(event) => event.stopPropagation()}
      />
      <StyledFancyRadioFigure checked={checked} size={size} />
    </>
  );

  return !labelIsProvidedViaHtmlFor ? (
    <StyledFancyRadioLabel
      className={className}
      disabled={disabled}
      optionWidth={optionWidth}
      styleAsRows={styleAsRows}
      htmlFor={elementId}
      size={size}
      style={style}
      {...other}
    >
      {radioInput}
      <Box display="inline-block" marginLeft="4x" sx={hideLabel ? A11yHidden : undefined}>
        <Text size="200">{label}</Text>
      </Box>
    </StyledFancyRadioLabel>
  ) : (
    radioInput
  );
};

export default FancyRadio;
