import React from 'react';

import { Button, Heading, Text } from '@rover/kibble/core';
import BottomSheet from '@rover/kibble/official-patterns/BottomSheet';

export type props = {
  isOpen: boolean;
  onRequestClose: () => void;
  heading?: string;
  text?: React.ReactNode;
  buttonText?: string;
};

const BottomInfoModal = ({
  heading,
  text,
  buttonText,
  isOpen,
  onRequestClose,
}: props): JSX.Element => {
  return (
    <BottomSheet isOpen={isOpen} onRequestClose={onRequestClose}>
      {heading && (
        <Heading size="300" mb="1x">
          {heading}
        </Heading>
      )}
      {text}
      {buttonText && (
        <Button variant="primary" size="small" fullWidth onClick={() => onRequestClose()}>
          <Text size="100">{buttonText}</Text>
        </Button>
      )}
    </BottomSheet>
  );
};

export default BottomInfoModal;
